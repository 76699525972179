<template>
  <div class="quotation__container">
    <div>
      <form @submit.prevent="submitCompany">
        <h2 class="quotation__subheading">Sourcing @ Magic</h2>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">E-mail</div>
            <input type="email" class="quotation__input" v-model.trim="email" placeholder="Enter your e-mail." />
          </label>
        </div>
        <div class="quotation__control">
          <div class="quotation__label">
            <div class="quotation__label-text">Upload Design Sketch</div>
            <div class="quotation__file" v-if="quotation.productSketch">
              <a class="quotation__file-link" :href="$blobUrl + '/' + quotation.productSketch" target="_blank">
                {{ quotation.productSketch.split('/')[1] }}
              </a>
              <span class="quotation__file-delete" title="Delete file" @click="quotation.productSketch = null"></span>
            </div>
            <input
              v-else
              type="file"
              class="quotation__input-file"
              placeholder="Upload file"
              data-obj="quotation"
              data-ref="productSketch"
              @change="onFileChange"
              accept="image/*,.pdf"
            />
          </div>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Total Quantity</div>
            <input type="text" class="quotation__input" v-model.trim="quotation.qiTotalQuantity" placeholder="" />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Target price FOB/FCA</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.qiTargetPrice"
              placeholder="Enter target price on FOB/FCA level."
            />
          </label>
        </div>

        <h2 class="quotation__subheading">Company Information</h2>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Company Name</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.name"
              placeholder="Enter your company name."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Company Description</div>
            <textarea
              class="quotation__textarea"
              v-model.trim="company.description"
              placeholder="What is your copmany all about? Why are you interesting to work with?"
            ></textarea>
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Size of Business</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.sizeOfBusiness"
              placeholder="Enter company turnover."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Website</div>
            <input
              type="text"
              pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
              title="URL is not valid"
              class="quotation__input"
              v-model.trim="company.website"
              placeholder="Enter company website name."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Languages Spoken</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.languagesSpoken"
              placeholder="Which languages can you accept a reply in?"
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Competitors</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.competitors"
              placeholder="Who do you consider to be your main competitor(s)?"
            />
          </label>
        </div>
        <h2 class="quotation__subheading">Requirements for Product and Production</h2>
        <div class="quotation__control">
          <div class="quotation__label">
            <div class="quotation__label-text">Social Code of Conduct (for suppliers to follow)</div>
            <div class="quotation__file" v-if="company.requirementsSocialFile">
              <a class="quotation__file-link" :href="$blobUrl + '/' + company.requirementsSocialFile" target="_blank">
                {{ company.requirementsSocialFile.split('/')[1] }}
              </a>
              <span
                class="quotation__file-delete"
                title="Delete file"
                @click="company.requirementsSocialFile = null"
              ></span>
            </div>
            <input
              v-else
              type="file"
              class="quotation__input-file"
              placeholder="Upload file"
              data-obj="company"
              data-ref="requirementsSocialFile"
              @change="onFileChange"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.requirementsSocialText"
              placeholder="Comment and/or reference to third party organization."
            />
          </div>
        </div>
        <div class="quotation__control">
          <div class="quotation__label">
            <div class="quotation__label-text">Chemical substance restrictions</div>
            <div class="quotation__file" v-if="company.requirementsChemicalFile">
              <a class="quotation__file-link" :href="$blobUrl + '/' + company.requirementsChemicalFile" target="_blank">
                {{ company.requirementsChemicalFile.split('/')[1] }}
              </a>
              <span
                class="quotation__file-delete"
                title="Delete file"
                @click="company.requirementsChemicalFile = null"
              ></span>
            </div>
            <input
              v-else
              type="file"
              class="quotation__input-file"
              placeholder="Upload file"
              data-obj="company"
              data-ref="requirementsChemicalFile"
              @change="onFileChange"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.requirementsChemicalText"
              placeholder="Comment and/or reference to third party organization."
            />
          </div>
        </div>
        <div class="quotation__control">
          <div class="quotation__label">
            <div class="quotation__label-text">Physical product requirements</div>
            <div class="quotation__file" v-if="company.requirementsPhysicalFile">
              <a class="quotation__file-link" :href="$blobUrl + '/' + company.requirementsPhysicalFile" target="_blank">
                {{ company.requirementsPhysicalFile.split('/')[1] }}
              </a>
              <span
                class="quotation__file-delete"
                title="Delete file"
                @click="company.requirementsPhysicalFile = null"
              ></span>
            </div>
            <input
              v-else
              type="file"
              class="quotation__input-file"
              placeholder="Upload file"
              data-obj="company"
              data-ref="requirementsPhysicalFile"
              @change="onFileChange"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.requirementsPhysicalText"
              placeholder="Comment and/or reference to third party organization."
            />
          </div>
        </div>
        <div class="quotation__control">
          <div class="quotation__label">
            <div class="quotation__label-text">Environmental requirements</div>
            <div class="quotation__file" v-if="company.requirementsEnvironmentalFile">
              <a
                class="quotation__file-link"
                :href="$blobUrl + '/' + company.requirementsEnvironmentalFile"
                target="_blank"
              >
                {{ company.requirementsEnvironmentalFile.split('/')[1] }}
              </a>
              <span
                class="quotation__file-delete"
                title="Delete file"
                @click="company.requirementsEnvironmentalFile = null"
              ></span>
            </div>
            <input
              v-else
              type="file"
              class="quotation__input-file"
              placeholder="Upload file"
              data-obj="company"
              data-ref="requirementsEnvironmentalFile"
              @change="onFileChange"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />
            <input
              type="text"
              class="quotation__input"
              v-model.trim="company.requirementsEnvironmentalText"
              placeholder="Comment and/or reference to third party organization."
            />
          </div>
        </div>
        <h2 class="quotation__subheading">Product Information</h2>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Product Name</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.productName"
              placeholder="Enter Product Name."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Product Type</div>
            <select
              class="quotation__select"
              :class="{ 'quotation__select--placeholder': !quotation.productType }"
              v-model="quotation.productType"
            >
              <option value="" disabled>Select from dropdown.</option>
              <option v-for="(ft, i) in footwearTypes" :value="ft.title" :key="i">{{ ft.title }}</option>
            </select>
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Production Process</div>
            <select
              class="quotation__select"
              :class="{ 'quotation__select--placeholder': !quotation.productProductionProcess }"
              v-model="quotation.productProductionProcess"
            >
              <option value="" disabled>Select desired production process.</option>
              <option v-for="(process, i) in processes" :value="process.title" :key="i">{{ process.title }}</option>
            </select>
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Materials Upper</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.productMaterialsUpper"
              placeholder="What is the main material for the upper?"
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Materials Sole</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.productMaterialsSole"
              placeholder="What is the main material for the outsole?"
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Prefered Country/Region</div>
            <select
              class="quotation__select"
              :class="{ 'quotation__select--placeholder': !quotation.preferedRegion }"
              v-model="quotation.preferedRegion"
            >
              <option value="" disabled selected>Enter if any prefered production region.</option>
              <option disabled class="quotation__select-separator">────────────────────</option>
              <option value="Europe">Europe</option>
              <option value="Middle East">Middle East</option>
              <option value="Asia">Asia</option>
              <option value="Africa">Africa</option>
              <option value="North America">North America</option>
              <option value="South America">South America</option>
              <option value="Oceania">Oceania</option>
              <option disabled class="quotation__select-separator">────────────────────</option>
              <option v-for="(country, i) in $store.countries.all" :value="country" :key="i">{{ country }}</option>
            </select>
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Existing Relationships</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.existingRelationships"
              placeholder="List any suppliers we should avoid contacting"
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Product Description</div>
            <textarea
              class="quotation__textarea"
              v-model.trim="quotation.productDescription"
              placeholder="What do you want this product to be? Describe the purpose of the product."
            ></textarea>
          </label>
        </div>
        <div class="quotation__control">
          <div class="quotation__label-text">Product Focus</div>
          <QuotationProductFocus
            :quality="quotation.productFocusQuality"
            :price="quotation.productFocusPrice"
            :speed="quotation.productFocusSpeed"
            @change="productFocusChange"
          >
          </QuotationProductFocus>
        </div>
        <h2 class="quotation__subheading">Quotation Information</h2>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Planned Order Quantity by Article</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.qiPlannedOrderQuantity"
              placeholder="How big quantity in pairs is planned? Used for quoting price."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Planned Articles</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.qiPlannedArticles"
              placeholder="How many different articles is planned for this product?"
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Size Range</div>
            <strong>{{ quotation.qiSizeRangeFrom }} - {{ quotation.qiSizeRangeTo }}</strong>
            <vue-slider
              class="supplier__slider-slider"
              :value="[quotation.qiSizeRangeFrom, quotation.qiSizeRangeTo]"
              :min="11"
              :max="55"
              :width="'100%'"
              :tooltip="false"
              :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
              :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
              :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
              @callback="watchSizeRange"
            >
            </vue-slider>
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Packaging Cost</div>
            <input
              type="text"
              class="quotation__input"
              v-model.trim="quotation.qiPackagingCost"
              placeholder="Enter your rough cost for packaging and labels."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Shipping Destination</div>
            <input
              class="quotation__input"
              v-model.trim="quotation.qiShippingDestination"
              placeholder="Info about destination enables best supplier suggestions."
            />
          </label>
        </div>
        <div class="quotation__control">
          <label class="quotation__label">
            <div class="quotation__label-text">Date to Receive Goods</div>
            <input
              class="quotation__input"
              v-model.trim="quotation.qiDateToReceiveGoods"
              placeholder="Add which date you want to have recieved the products."
            />
          </label>
        </div>
        <div class="quotation__control quotation__control--center">
          <button class="quotation__submit" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import QuotationProductFocus from './quotation-product-focus.vue';

import VueSlider from 'vue-slider-component/src/vue2-slider.vue';

export default {
  name: 'Vegas',
  load({ store, route }) {
    store.head.title = `FindSourcing – Source Suppliers`;
  },
  components: { QuotationProductFocus, VueSlider },
  data() {
    return {
      email: '',
      company: {
        name: '',
        description: '',
        website: '',
        sizeOfBusiness: '',
        languagesSpoken: '',
        competitors: '',
        // Requirements for Product and Production
        requirementsSocialFile: '',
        requirementsSocialText: '',
        requirementsChemicalFile: '',
        requirementsChemicalText: '',
        requirementsPhysicalFile: '',
        requirementsPhysicalText: '',
        requirementsEnvironmentalFile: '',
        requirementsEnvironmentalText: '',
      },
      quotation: {
        // Product Informtion
        productName: '',
        productSketch: '',
        productType: '',
        productProductionProcess: '',
        productMaterialsUpper: '',
        productMaterialsSole: '',
        preferedRegion: '',
        existingRelationships: '',
        productDescription: '',
        productFocusQuality: 3,
        productFocusPrice: 3,
        productFocusSpeed: 3,
        // Quotation Infomration
        qiPlannedOrderQuantity: '',
        qiPlannedArticles: '',
        qiTotalQuantity: '',
        qiTargetPrice: '',
        qiSizeRangeFrom: 11,
        qiSizeRangeTo: 55,
        qiPackagingCost: '',
        qiDateToReceiveGoods: '',
        qiShippingDestination: '',
      },
      loadingFiles: [],
    };
  },
  computed: {
    footwearTypes() {
      return this.$store.categories.all.find((c) => c.slug == 'footwear-types').articles;
    },
    processes() {
      return this.$store.categories.all.find((c) => c.slug == 'construction').articles;
    },
  },
  methods: {
    fileToData(file) {
      if (!file) return null;
      let formData = new FormData();
      formData.append('file', file);
      return formData;
    },
    onFileChange(e) {
      const input = e.target;
      const { obj, ref } = input.dataset;
      if (input.files && input.files[0]) {
        const file = this.fileToData(input.files[0]);
        // The reason we don't just push this.loadFile directly is since we need to return it,
        // which we need to do in order for proper error propagation (when babeling/transpiling
        // our code for older browser compatibility).
        const promise = this.loadFile(file, obj, ref);
        this.loadingFiles.push(promise);
        return promise;
      } else {
        this[obj][ref] = null;
      }
    },
    async loadFile(file, obj, ref) {
      const { file: img } = await this.$store.companies.uploadFile(file);
      this[obj][ref] = img;
    },
    async submitCompany() {
      this.$store.general.loading = true;

      let { success } = await this.$store.user.registerBuyerWithoutPassword(this.email);

      if (!success) {
        this.$store.general.loading = true;

        this.$notify({
          type: 'error',
          title: `Could not register user with ${this.email} - please try and log in/change e-mail.`,
        });

        return;
      }

      if (this.loadingFiles.length) {
        await Promise.all(this.loadingFiles);
        this.loadingFiles = [];
      }

      this.company.id = this.$store.user.company.id;

      await this.$store.buyers.save(this.company);

      await this.$store.quotations.addRequest(this.quotation);

      setTimeout(() => {
        this.$router.push({ name: 'quotationOverview' });
      }, 5000);
    },
    productFocusChange(values) {
      this.quotation.productFocusQuality = values.quality;
      this.quotation.productFocusSpeed = values.speed;
      this.quotation.productFocusPrice = values.price;
    },
    watchSizeRange(value) {
      (this.quotation.qiSizeRangeFrom = value[0]), (this.quotation.qiSizeRangeTo = value[1]);
    },
  },
};
</script>
